import type { SessionStore, PendingUserInfo } from "../types"
import { type Readable, type Writable, writable, derived } from 'svelte/store';
import { browser } from "$app/environment";
import type { SubmitDownloadBrochureResponse } from "$lib/api/generated/cta-services";

const createLocalStore = (key, startValue) => {
    const {
        subscribe,
        set,
        update
    } = writable(startValue)

    return {
        subscribe,
        set,
        update,
        useLocalStorage: () => {
            const json = localStorage.getItem(key);
            if (json) {
                set(JSON.parse(json));
            }

            subscribe(current => {
                localStorage.setItem(key, JSON.stringify(current));
            });
        }
    };
}

const createSessionStore = (key, startValue) => {
    const store = writable(startValue);
    return {
        ...store,
        useSessionStore: () => {
            const json = sessionStorage.getItem(key);
            if(json) {
                store.set(JSON.parse(json));
            }
            store.subscribe(current => sessionStorage.setItem(key, JSON.stringify(current)));
        }
    }
}

/**
 * Basic implementation of session store
 */
export class SessionStoreImpl implements SessionStore {

    private static _instance: SessionStoreImpl | null;
    private _pendingUserInfo: Writable<PendingUserInfo>;
    private static PENDING_USER_INFO_KEY = "pendingUserInfo";

    private _downloadBrochureResult: Writable<SubmitDownloadBrochureResponse>;
    private static DOWNLOAD_BROCHURE_RESULT_KEY = "downloadBrochureResult";

    private _loginOptions: Writable<{ email: string; channel: string}>;
    private static LOGIN_OPTIONS_KEY = "loginOptions";

    private _companyStatus: Writable<boolean>;
    private static COMPANY_STATUS_KEY = "CompanyStatus";

    private _selectedCompanyStatus: Writable<boolean>;
    private static SELECTED_COMPANY_STATUS_KEY = "selectedCompanyStatus";

    private constructor() {
        this._pendingUserInfo = createLocalStore(SessionStoreImpl.PENDING_USER_INFO_KEY, null);
        this._downloadBrochureResult = createSessionStore(SessionStoreImpl.DOWNLOAD_BROCHURE_RESULT_KEY, null);
        this._loginOptions = createLocalStore(SessionStoreImpl.LOGIN_OPTIONS_KEY,  null);
        this._companyStatus = createLocalStore(SessionStoreImpl.COMPANY_STATUS_KEY,  false);
        this._selectedCompanyStatus = createLocalStore(SessionStoreImpl.SELECTED_COMPANY_STATUS_KEY,  null);

        if (browser) {
            (this._pendingUserInfo as any).useLocalStorage();
            (this._downloadBrochureResult as any).useSessionStore();
            (this._loginOptions as any).useLocalStorage();
            (this._companyStatus as any).useLocalStorage();
            (this._selectedCompanyStatus as any).useLocalStorage();
        }
    }

    public static getInstance() {
        if (SessionStoreImpl._instance == null) {
            SessionStoreImpl._instance = new SessionStoreImpl();
        }

        return SessionStoreImpl._instance;
    }

    /**
     * Store the not confirmed user info into the session.
     * @param userInfo The user info to store.
     */
    public setPendingUserInfo(userInfo: PendingUserInfo) {
        this._pendingUserInfo.set(userInfo);
    }

    /**
     * Clean the pending user info fro the store.
     */
    public cleanPendingUserInfo() {
        this._pendingUserInfo.set(null);
    }

    /**
     * Get the unconfirmed user info from the session.
     */
    get pendingUserInfo(): Readable<PendingUserInfo> {
        return derived(this._pendingUserInfo, (value) => { return value });
    }


    /**
     * Store te download brochure result into the session
     * @param downloadBrochureResult  The result to store
     */
    setLoginOptions(loginOptions: { email: string; channel: string }) {
        this._loginOptions.set(loginOptions);
    }


    /**
     * Clean the login options from the session store
     */
    cleanLoginOptions() {
        this._loginOptions.set(null);
    }


    get loginOptions() {
        return  derived(this._loginOptions, (value) => (value));
    }


    /**
     * Store the login options into the local store
     * @param downloadBrochureResult  The result to store
     */
    setDownloadBrochureResult(downloadBrochureResult: DownloadBrochureResult) {
        this._downloadBrochureResult.set(downloadBrochureResult);
    }


    /**
     * Clean the download brochure result from the session store
     */
    cleanDownloadBrochureResult() {
        this._downloadBrochureResult.set(null);
    }


    /**
      * Get the company status result from the local storage
      */
    get downloadBrochureresult(): Readable<DownloadBrochureResult> {
        return derived(this._downloadBrochureResult, (value) => (value));
    }


    /**
     * Store the company status result into the local store
     */
    setCompanyStatus(companyStatus: boolean) {
        this._companyStatus.set(companyStatus);
        this._selectedCompanyStatus.set(true);
    }


    /**
     * Clean the company status from the local store
     */
    cleanCompanyStatus() {
        this._companyStatus.set(null);
    }


    get companyStatus() {
        return  derived(this._companyStatus, (value) => (value));
    }

     /**
     * Store the company status selected result into the local store
     */
    setSelectedCompanyStatus(companyStatus: boolean) {
        this._selectedCompanyStatus.set(companyStatus);
    }

    get selectedCompanyStatus() {
        return  derived(this._selectedCompanyStatus, (value) => (value));
    }
}